/*! themes/_green.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Green theme variables and styles (Green theme is used in "Landing kit v2" )
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Theme specific Hero
4. Helm Specific Styling
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #fed701;
$secondary: #00a6a9;
$accent: #7d65ac;
$warning: #f99a1c;
$danger: #d43883;
$info: #0073bb;
$success: #66b445;

/* === ICONS === */
.icon-box.primary svg .st0 {fill: $primary}
.icon-box.secondary svg .st0 {fill: $secondary}
.icon-box.accent svg .st0 {fill: $accent}
.icon-box.warning svg .st0 {fill: $warning}
.icon-box.danger svg .st0 {fill: $danger}
.icon-box.info svg .st0 {fill: $info}
.icon-box.success svg .st0 {fill: $success}

/* ==========================================================================
1. Theme Gradient
========================================================================== */
$webkit-hero-gradient: -webkit-linear-gradient(to right, $accent, $secondary);
$hero-gradient: linear-gradient(to right, $accent, $secondary);

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(0, 178, 137, 0.42);
$primary-shadow-to: rgba(0, 178, 137, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(0, 178, 137, 0.42);
$secondary-shadow-to: rgba(0, 178, 137, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(0, 178, 137, 0.42);
$accent-shadow-to: rgba(0, 178, 137, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;

/* ==========================================================================
3. Theme specific Hero
========================================================================== */
.hero, .section {
    &.is-theme-primary {
        background-color: $primary;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
    }
}

/* ==========================================================================
3. Helm specific styling
========================================================================== */
p {
    margin-bottom: 1em;
}

p:last-child {
    margin-bottom: 0;
}

.bold{font-weight:bold;}
.italic{font-style:italic;}
