/*! _layout-utils.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Bulkit utils
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Resets and base setup
1. Back to top button
2. Progress bars
3. Input placeholders
4. Window scrollbar
5. Demo link colors
=============================================================================
***/

/* ==========================================================================
0. Resets and base setup
========================================================================== */

html {
    background-color: #fef6e2;
    font-size:18px;
    font-variant-ligatures: none;
}

body {
    font-family: "Archer", serif;
    overflow: hidden !important;
}

a, a:active, i, i:active, div, div:active, div:focus, span:focus, span:active, {
    outline: none !important;
}

h1, h2, h3{
    line-height:1.2;
}

.highlight-primary{color:$primary;font-weight:bold;}
.highlight-secondary{color:$secondary;font-weight:bold;}
.highlight-accent{color:$accent;font-weight:bold;}
.highlight-warning{color:$warning;font-weight:bold;}
.highlight-danger{color:$danger;font-weight:bold;}
.highlight-info{color:$info;font-weight:bold;}
.highlight-success{color:$success;font-weight:bold;}

$primary: #fed701;
$secondary: #00a6a9;
$accent: #7d65ac;
$warning: #f99a1c;
$danger: #d43883;
$info: #0073bb;
$success: #66b445;

.button{
    font-size:1.4rem;
    @media (max-width: 430px) {
        font-size:1rem;
    }
}

.button-signup{font-size:1rem;}

.button.is-active, .button:active, .button:focus {
    box-shadow: none !important
}

section:focus {
    outline: none !important;
}

.BasicTextColor {color:var(--BasicTextColor) !important;}
#tour .dark-text{color:var(--BasicTextColor) !important}
.grid-clients a{cursor:default;}

.logo-company-name{
    font-size:32px;
    color:var(--BasicTextColor);
    line-height:1;
    margin-right:40px;
    &:hover{color:$blue-grey;}
}



.is-transparent .logo-company-name{color:white;}
.is-transparent.is-semisolid  .logo-company-name{color:black;}

@font-face {
    font-family: 'Archer';
    src: url('archer-book.eot');
    src: url('archer-book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/archer-book.svg#archer-book') format('svg'),
         url('../fonts/archer-book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
font-family: 'Archer';
src: url('archer-bookitalic.eot');
src: url('archer-bookitalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/archer-bookitalic.svg#archer-bookitalic') format('svg'),
        url('../fonts/archer-bookitalic.ttf') format('truetype');
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: 'Archer';
src: url('archer-bold.eot');
src: url('archer-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/archer-bold.svg#archer-bold') format('svg'),
        url('../fonts/archer-bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'Archer';
src: url('archer-bolditalic.eot');
src: url('archer-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/archer-bolditalic.svg#archer-bolditalic') format('svg'),
        url('../fonts/archer-bolditalic.ttf') format('truetype');
font-weight: bold;
font-style: italic;
}

@font-face {
font-family: 'Avenir';
src: url('avenirltstd-book.eot');
src: url('avenirltstd-book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/avenirltstd-book.svg#avenirltstd-book') format('svg'),
        url('../fonts/avenirltstd-book.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Avenir';
src: url('avenirltstd-bookoblique.eot');
src: url('avenirltstd-bookoblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/avenirltstd-bookoblique.svg#avenirltstd-bookoblique') format('svg'),
        url('../fonts/avenirltstd-bookoblique.ttf') format('truetype');
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: 'Avenir';
src: url('avenirltstd-black.eot');
src: url('avenirltstd-black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/avenirltstd-black.svg#avenirltstd-black') format('svg'),
        url('../fonts/avenirltstd-black.ttf') format('truetype');
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'Avenir';
src: url('avenirltstd-blackoblique.eot');
src: url('avenirltstd-blackoblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/avenirltstd-blackoblique.svg#avenirltstd-blackoblique') format('svg'),
        url('../fonts/avenirltstd-blackoblique.ttf') format('truetype');
font-weight: bold;
font-style: italic;
}

@font-face {
font-family: 'Avenir';
src: url('../fonts/avenirltstd-medium.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}


/* ==========================================================================
1. Back to top button
========================================================================== */

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transition: 0.35s;
    transform: scale(0.7);
    transition: all 0.5s;
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);

}

#backtotop.visible a:hover {
    outline: none;
    opacity: 0.9;
    background: $secondary;
}

#backtotop a {
    outline: none;
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $medium-grey;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 50%;
    text-align: center;
    font-size: 26px
}

body #backtotop a {
    outline: none;
    color: #fff;
}

#backtotop a:after {
    outline: none;
    content: "\f106";
    font-family: "FontAwesome";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
}


/* ==========================================================================
2. Progress bars
========================================================================== */

.progress {
    width: 100% !important;
}

.progress.primary::-webkit-progress-value {
    background-color: $primary !important;
}

.progress.secondary::-moz-progress-bar {
    background-color: $secondary !important;
}


/* ==========================================================================
3. Input placeholders
========================================================================== */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}


/* ==========================================================================
4. Window scrollbar
========================================================================== */

*::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    width: 10px;
}
*::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0,0,0,0.2)
}

/* ==========================================================================
5. CAPTCHA
========================================================================== */
.grecaptcha-badge {display:none;}
