/*
Gray
Gray50: rgba(249,249,249,1);
Gray100: rgba(244,244,244,1);
Gray200: rgba(226,226,226,1);
Gray300: rgba(203,203,202,1); lightgray #ddd
Gray400: rgba(174,175,175,1); #999
Gray500: rgba(140,143,145,1); gray #666
Gray600: rgba(123,125,127,1);
Gray700: rgba(100,102,102,1);
Gray800: rgba(75,77,79,1);
Gray900: rgba(54,55,56,1);

Green
Green50: rgba(246,255,237,1);
Green100: rgba(241,255,221,1);
Green200: rgba(227,254,185,1);
Green300: rgba(185,245,137,1);
Green400: rgba(139,222,95,1);
Green500: rgba(93,194,56,1);
Green600: rgba(78,166,56,1);
Green700: rgba(53,137,40,1);
Green800: rgba(38,104,25,1);
Green900: rgba(25,88,21,1);

GreenA100: rgba(235,255,195,1);
GreenA200: rgba(203,249,138,1);
GreenA300: rgba(173,237,96,1);
GreenA400: rgba(118,219,65,1);

Yellow
Yellow100: rgba(255,251,245,1);
Yellow100: rgba(255,248,235,1);
Yellow200: rgba(255,245,202,1);
Yellow300: rgba(255,240,176,1);
Yellow400: rgba(255,224,129,1);
Yellow500: rgba(255,215,0,1);
Yellow600: rgba(255,201,58,1);
Yellow700: rgba(245,186,58,1);
Yellow800: rgba(217,156,52,1);
Yellow900: rgba(186,124,51,1);

YellowA100: rgba(254,247,221,1);
YellowA200: rgba(255,241,166,1);
YellowA300: rgba(255,238,129,1);
YellowA400: rgba(255,230,84,1);

Orange
Orange100: rgba(255,240,220,1);
Orange200: rgba(255,228,190,1);
Orange300: rgba(255,203,142,1);
Orange400: rgba(252,178,104,1);
Orange500: rgba(249,154,28,1);
Orange600: rgba(229,127,0,1);
Orange700: rgba(203,101,0,1);
Orange800: rgba(164,78,3,1);
Orange900: rgba(122,63,10,1);

OrangeA100: rgba(255,234,201,1);
OrangeA200: rgba(255,222,173,1);
OrangeA300: rgba(255,196,120,1);
OrangeA400: rgba(247,177,85,1);

Red
Red50: rgba(255,244,243,1);
Red100: rgba(255,230,227,1);
Red200: rgba(255,210,210,1);
Red300: rgba(247,159,159,1);
Red400: rgba(239,108,108,1);
Red500: rgba(210,43,42,1);
Red600: rgba(175,34,34,1);
Red700: rgba(142,23,23,1);
Red800: rgba(107,18,18,1);
Red900: rgba(83,11,15,1);

RedA100: rgba(255,216,204,1);
RedA200: rgba(255,177,161,1);
RedA300: rgba(255,148,126,1);
RedA400: rgba(249,95,77,1);

Magenta
Magenta100: rgba(255,235,247,1);
Magenta200: rgba(255,202,235,1);
Magenta300: rgba(242,143,200,1);
Magenta400: rgba(224,105,170,1);
Magenta500: rgba(212,56,131,1);
Magenta600: rgba(188,50,118,1);
Magenta700: rgba(167,32,100,1);
Magenta800: rgba(128,19,80,1);
Magenta900: rgba(84,4,52,1);

MagentaA100: rgba(255,217,239,1);
MagentaA200: rgba(255,185,225,1);
MagentaA300: rgba(244,122,187,1);
MagentaA400: rgba(226,82,155,1);

Purple
Purple50: rgba(248,246,255,1);
Purple100: rgba(236,229,255,1);
Purple200: rgba(215,204,250,1);
Purple300: rgba(179,161,229,1);
Purple400: rgba(147,131,201,1);
Purple500: rgba(125,101,172,1);
Purple600: rgba(105,85,153,1);
Purple700: rgba(93,63,131,1);
Purple800: rgba(67,53,99,1);
Purple900: rgba(46,35,66,1);

PurpleA100: rgba(240,224,255,1);
PurpleA200: rgba(203,179,255,1);
PurpleA300: rgba(166,139,234,1);
PurpleA400: rgba(143,119,201,1);

Blue
Blue100: rgba(230,247,255,1);
Blue200: rgba(153,222,255,1);
Blue300: rgba(115,196,249,1);
Blue400: rgba(50,155,230,1);
Blue500: rgba(0,115,187,1);
Blue600: rgba(0,96,158,1);
Blue700: rgba(9,75,115,1);
Blue800: rgba(10,61,84,1);
Blue900: rgba(6,47,63,1);

BlueA100: rgba(215,247,255,1);
BlueA200: rgba(128,228,255,1);
BlueA300: rgba(29,190,255,1);
BlueA400: rgba(32,146,238,1);

Turquoise
Turquoise100: rgba(229,249,245,1);
Turquoise200: rgba(193,247,236,1);
Turquoise300: rgba(155,234,220,1);
Turquoise400: rgba(100,216,202,1);
Turquoise500: rgba(0,179,169,1);
Turquoise600: rgba(14,135,141,1);
Turquoise700: rgba(13,91,99,1);
Turquoise800: rgba(16,57,66,1);
Turquoise900: rgba(2,43,54,1);

TurquoiseA100: rgba(217,255,249,1);
TurquoiseA200: rgba(160,244,238,1);
TurquoiseA300: rgba(106,232,222,1);
TurquoiseA400: rgba(55,215,200,1);

*/



/*

Main colors are the 500s:
Gray:		    #8c8f91	rgba(140,143,145,1)
Green:		  	#5dc238	rgba(93,194,56,1)
Yellow:		  	#ffd700	rgba(255,215,0,1)
Orange: 	  	#f99a1c	rgba(249,154,28,1)
Red:		    #d22b2a	rgba(210,43,42,1)
Magenta:   		#d43883	rgba(212,56,131,1)
Purple: 	  	#7d65ac	rgba(125,101,172,1)
Blue:		    #0073bb	rgba(0,115,187,1)
Turquoise:  	#00b3a9	rgba(0,179,169,1)

*/

:root {
  /* White */
  --WhiteRGB: 255,255,255;
  --White: rgba(var(--WhiteRGB),1.0);


  /* Gray */
  --Gray50RGB: 249,249,249;
  --Gray100RGB: 244,244,244;
  --Gray200RGB: 226,226,226;
  --Gray300RGB: 203,203,202;
  --Gray400RGB: 174,175,175;
  --Gray500RGB: 140,143,145;
  --Gray600RGB: 123,125,127;
  --Gray700RGB: 100,102,102;
  --Gray800RGB: 75,77,79;
  --Gray900RGB: 54,55,56;

  --Gray50: rgba(var(--Gray50RGB),1.0);
  --Gray100: rgba(var(--Gray100RGB),1.0);
  --Gray200: rgba(var(--Gray200RGB),1.0);
  --Gray300: rgba(var(--Gray300RGB),1.0);
  --Gray400: rgba(var(--Gray400RGB),1.0);
  --Gray500: rgba(var(--Gray500RGB),1.0);
  --Gray600: rgba(var(--Gray600RGB),1.0);
  --Gray700: rgba(var(--Gray700RGB),1.0);
  --Gray800: rgba(var(--Gray800RGB),1.0);
  --Gray900: rgba(var(--Gray900RGB),1.0);


  /* Green */
  --Green50RGB: 246,255,237;
  --Green100RGB: 241,255,221;
  --Green200RGB: 227,254,185;
  --Green300RGB: 185,245,137;
  --Green400RGB: 139,222,95;
  --Green500RGB: 93,194,56;
  --Green600RGB: 78,166,56;
  --Green700RGB: 53,137,40;
  --Green800RGB: 38,104,25;
  --Green900RGB: 25,88,21;

  --GreenA100RGB: 235,255,195;
  --GreenA200RGB: 203,249,138;
  --GreenA300RGB: 173,237,96;
  --GreenA400RGB: 118,219,65;

  --Green50: rgba(var(--Green50RGB),1.0);
  --Green100: rgba(var(--Green100RGB),1.0);
  --Green200: rgba(var(--Green200RGB),1.0);
  --Green300: rgba(var(--Green300RGB),1.0);
  --Green400: rgba(var(--Green400RGB),1.0);
  --Green500: rgba(var(--Green500RGB),1.0);
  --Green600: rgba(var(--Green600RGB),1.0);
  --Green700: rgba(var(--Green700RGB),1.0);
  --Green800: rgba(var(--Green800RGB),1.0);
  --Green900: rgba(var(--Green900RGB),1.0);

  --GreenA100: rgba(var(--GreenA100RGB),1.0);
  --GreenA200: rgba(var(--GreenA200RGB),1.0);
  --GreenA300: rgba(var(--GreenA300RGB),1.0);
  --GreenA400: rgba(var(--GreenA400RGB),1.0);


  /* Yellow */
  --Yellow50RGB: 255,251,245;
  --Yellow100RGB: 255,248,235;
  --Yellow200RGB: 255,245,202;
  --Yellow300RGB: 255,240,176;
  --Yellow400RGB: 255,224,129;
  --Yellow500RGB: 255,215,0;
  --Yellow600RGB: 255,201,58;
  --Yellow700RGB: 245,186,58;
  --Yellow800RGB: 217,156,52;
  --Yellow900RGB: 186,124,51;

  --YellowA100RGB: 254,247,221;
  --YellowA200RGB: 255,241,166;
  --YellowA300RGB: 255,238,129;
  --YellowA400RGB: 255,230,84;

  --Yellow50: rgba(var(--Yellow50RGB),1.0);
  --Yellow100: rgba(var(--Yellow100RGB),1.0);
  --Yellow200: rgba(var(--Yellow200RGB),1.0);
  --Yellow300: rgba(var(--Yellow300RGB),1.0);
  --Yellow400: rgba(var(--Yellow400RGB),1.0);
  --Yellow500: rgba(var(--Yellow500RGB),1.0);
  --Yellow600: rgba(var(--Yellow600RGB),1.0);
  --Yellow700: rgba(var(--Yellow700RGB),1.0);
  --Yellow800: rgba(var(--Yellow800RGB),1.0);
  --Yellow900: rgba(var(--Yellow900RGB),1.0);

  --YellowA100: rgba(var(--YellowA100RGB),1.0);
  --YellowA200: rgba(var(--YellowA200RGB),1.0);
  --YellowA300: rgba(var(--YellowA300RGB),1.0);
  --YellowA400: rgba(var(--YellowA400RGB),1.0);


  /* Orange */
  --Orange100RGB: 255,240,220;
  --Orange200RGB: 255,228,190;
  --Orange300RGB: 255,203,142;
  --Orange400RGB: 252,178,104;
  --Orange500RGB: 249,154,28;
  --Orange600RGB: 229,127,0;
  --Orange700RGB: 203,101,0;
  --Orange800RGB: 164,78,3;
  --Orange900RGB: 122,63,10;

  --OrangeA100RGB: 255,234,201;
  --OrangeA200RGB: 255,222,173;
  --OrangeA300RGB: 255,196,120;
  --OrangeA400RGB: 247,177,85;

  --Orange100: rgba(var(--Orange100RGB),1.0);
  --Orange200: rgba(var(--Orange200RGB),1.0);
  --Orange300: rgba(var(--Orange300RGB),1.0);
  --Orange400: rgba(var(--Orange400RGB),1.0);
  --Orange500: rgba(var(--Orange500RGB),1.0);
  --Orange600: rgba(var(--Orange600RGB),1.0);
  --Orange700: rgba(var(--Orange700RGB),1.0);
  --Orange800: rgba(var(--Orange800RGB),1.0);
  --Orange900: rgba(var(--Orange900RGB),1.0);

  --OrangeA100: rgba(var(--OrangeA100RGB),1.0);
  --OrangeA200: rgba(var(--OrangeA200RGB),1.0);
  --OrangeA300: rgba(var(--OrangeA300RGB),1.0);
  --OrangeA400: rgba(var(--OrangeA400RGB),1.0);


  /* Red */
  --Red50RGB: 255,244,243;
  --Red100RGB: 255,230,227;
  --Red200RGB: 255,210,210;
  --Red300RGB: 247,159,159;
  --Red400RGB: 239,108,108;
  --Red500RGB: 210,43,42;
  --Red600RGB: 175,34,34;
  --Red700RGB: 142,23,23;
  --Red800RGB: 107,18,18;
  --Red900RGB: 83,11,15;

  --RedA100RGB: 255,216,204;
  --RedA200RGB: 255,177,161;
  --RedA300RGB: 255,148,126;
  --RedA400RGB: 249,95,77;

  --Red50: rgba(var(--Red50RGB),1.0);
  --Red100: rgba(var(--Red100RGB),1.0);
  --Red200: rgba(var(--Red200RGB),1.0);
  --Red300: rgba(var(--Red300RGB),1.0);
  --Red400: rgba(var(--Red400RGB),1.0);
  --Red500: rgba(var(--Red500RGB),1.0);
  --Red600: rgba(var(--Red600RGB),1.0);
  --Red700: rgba(var(--Red700RGB),1.0);
  --Red800: rgba(var(--Red800RGB),1.0);
  --Red900: rgba(var(--Red900RGB),1.0);

  --RedA100: rgba(var(--RedA100RGB),1.0);
  --RedA200: rgba(var(--RedA200RGB),1.0);
  --RedA300: rgba(var(--RedA300RGB),1.0);
  --RedA400: rgba(var(--RedA400RGB),1.0);


  /* Magenta */
  --Magenta100RGB: 255,235,247;
  --Magenta200RGB: 255,202,235;
  --Magenta300RGB: 242,143,200;
  --Magenta400RGB: 224,105,170;
  --Magenta500RGB: 212,56,131;
  --Magenta600RGB: 188,50,118;
  --Magenta700RGB: 167,32,100;
  --Magenta800RGB: 128,19,80;
  --Magenta900RGB: 84,4,52;

  --MagentaA100RGB: 255,217,239;
  --MagentaA200RGB: 255,185,225;
  --MagentaA300RGB: 244,122,187;
  --MagentaA400RGB: 226,82,155;

  --Magenta100: rgba(var(--Magenta100RGB),1.0);
  --Magenta200: rgba(var(--Magenta200RGB),1.0);
  --Magenta300: rgba(var(--Magenta300RGB),1.0);
  --Magenta400: rgba(var(--Magenta400RGB),1.0);
  --Magenta500: rgba(var(--Magenta500RGB),1.0);
  --Magenta600: rgba(var(--Magenta600RGB),1.0);
  --Magenta700: rgba(var(--Magenta700RGB),1.0);
  --Magenta800: rgba(var(--Magenta800RGB),1.0);
  --Magenta900: rgba(var(--Magenta900RGB),1.0);

  --MagentaA100: rgba(var(--MagentaA100RGB),1.0);
  --MagentaA200: rgba(var(--MagentaA200RGB),1.0);
  --MagentaA300: rgba(var(--MagentaA300RGB),1.0);
  --MagentaA400: rgba(var(--MagentaA400RGB),1.0);


  /* Purple */
  --Purple50RGB: 248,246,255;
  --Purple100RGB: 236,229,255;
  --Purple200RGB: 215,204,250;
  --Purple300RGB: 179,161,229;
  --Purple400RGB: 147,131,201;
  --Purple500RGB: 125,101,172;
  --Purple600RGB: 105,85,153;
  --Purple700RGB: 93,63,131;
  --Purple800RGB: 67,53,99;
  --Purple900RGB: 46,35,66;

  --PurpleA100RGB: 240,224,255;
  --PurpleA200RGB: 203,179,255;
  --PurpleA300RGB: 166,139,234;
  --PurpleA400RGB: 143,119,201;

  --Purple50: rgba(var(--Purple50RGB),1.0);
  --Purple100: rgba(var(--Purple100RGB),1.0);
  --Purple200: rgba(var(--Purple200RGB),1.0);
  --Purple300: rgba(var(--Purple300RGB),1.0);
  --Purple400: rgba(var(--Purple400RGB),1.0);
  --Purple500: rgba(var(--Purple500RGB),1.0);
  --Purple600: rgba(var(--Purple600RGB),1.0);
  --Purple700: rgba(var(--Purple700RGB),1.0);
  --Purple800: rgba(var(--Purple800RGB),1.0);
  --Purple900: rgba(var(--Purple900RGB),1.0);

  --PurpleA100: rgba(var(--PurpleA100RGB),1.0);
  --PurpleA200: rgba(var(--PurpleA200RGB),1.0);
  --PurpleA300: rgba(var(--PurpleA300RGB),1.0);
  --PurpleA400: rgba(var(--PurpleA400RGB),1.0);


  /* Blue */
  --Blue100RGB: 230,247,255;
  --Blue200RGB: 153,222,255;
  --Blue300RGB: 115,196,249;
  --Blue400RGB: 50,155,230;
  --Blue500RGB: 0,115,187;
  --Blue600RGB: 0,96,158;
  --Blue700RGB: 9,75,115;
  --Blue800RGB: 10,61,84;
  --Blue900RGB: 6,47,63;

  --BlueA100RGB: 215,247,255;
  --BlueA200RGB: 128,228,255;
  --BlueA300RGB: 29,190,255;
  --BlueA400RGB: 32,146,238;

  --Blue100: rgba(var(--Blue100RGB),1.0);
  --Blue200: rgba(var(--Blue200RGB),1.0);
  --Blue300: rgba(var(--Blue300RGB),1.0);
  --Blue400: rgba(var(--Blue400RGB),1.0);
  --Blue500: rgba(var(--Blue500RGB),1.0);
  --Blue600: rgba(var(--Blue600RGB),1.0);
  --Blue700: rgba(var(--Blue700RGB),1.0);
  --Blue800: rgba(var(--Blue800RGB),1.0);
  --Blue900: rgba(var(--Blue900RGB),1.0);

  --BlueA100: rgba(var(--BlueA100RGB),1.0);
  --BlueA200: rgba(var(--BlueA200RGB),1.0);
  --BlueA300: rgba(var(--BlueA300RGB),1.0);
  --BlueA400: rgba(var(--BlueA400RGB),1.0);


  /* Turquoise */
  --Turquoise100RGB: 229,249,245;
  --Turquoise200RGB: 193,247,236;
  --Turquoise300RGB: 155,234,220;
  --Turquoise400RGB: 100,216,202;
  --Turquoise500RGB: 0,179,169;
  --Turquoise600RGB: 14,135,141;
  --Turquoise700RGB: 13,91,99;
  --Turquoise800RGB: 16,57,66;
  --Turquoise900RGB: 2,43,54;

  --TurquoiseA100RGB: 217,255,249;
  --TurquoiseA200RGB: 160,244,238;
  --TurquoiseA300RGB: 106,232,222;
  --TurquoiseA400RGB: 55,215,200;

  --Turquoise100: rgba(var(--Turquoise100RGB),1.0);
  --Turquoise200: rgba(var(--Turquoise200RGB),1.0);
  --Turquoise300: rgba(var(--Turquoise300RGB),1.0);
  --Turquoise400: rgba(var(--Turquoise400RGB),1.0);
  --Turquoise500: rgba(var(--Turquoise500RGB),1.0);
  --Turquoise600: rgba(var(--Turquoise600RGB),1.0);
  --Turquoise700: rgba(var(--Turquoise700RGB),1.0);
  --Turquoise800: rgba(var(--Turquoise800RGB),1.0);
  --Turquoise900: rgba(var(--Turquoise900RGB),1.0);

  --TurquoiseA100: rgba(var(--TurquoiseA100RGB),1.0);
  --TurquoiseA200: rgba(var(--TurquoiseA200RGB),1.0);
  --TurquoiseA300: rgba(var(--TurquoiseA300RGB),1.0);
  --TurquoiseA400: rgba(var(--TurquoiseA400RGB),1.0);

  /* Usage Variables */
  --ApplicationBlack: var(--Turquoise900);
  --BasicTextColor: var(--ApplicationBlack);
  --BoxShadowColor: rgba(var(--Gray900RGB),0.15);
  --BasicLinkColor: var(--Blue500);
  --ActionNeededColor: var(--Orange500);
  --GreenTextColor: var(--Green600);
  --RedTextColor: var(--Red600);
  --GrayTextColor:var(--Gray600);
  --GrayBorderColor:var(--Gray200);

  --HelmBotBrandColor:var(--Yellow500);

  --ReservationNotesColor: var(--Yellow400);
  --ReservationAvailableColor: var(--White);
  --ReservationAvailableNoPractitionerColor: var(--Red100);
  --ReservationAvailablePastColor: var(--Gray50);

  --ReservationBlockedColor: var(--Gray400);
  --ReservationBlockedBorderColor: var(--Gray500);
  --ReservationBlockedHoverColor: var(--Gray300);
  --ReservationBlockedTextColor: var(--Gray900);

  --ReservationClaimedColor: var(--Blue400);
  --ReservationClaimedServiceCreditAvailableColor: var(--Blue600);
  --ReservationClaimedSaleInProgressColor: var(--Yellow500);
  --ReservationClaimedSaleInProgressBorderColor: var(--Yellow600);

  --ReservationPaidColor: var(--Green600);
  --ReservationPaidAndReturnedColor: var(--Green800);
  --ReservationNoShowColor: var(--Red800);
  --ReservationNoShowTextColor: var(--Red300);
  --ReservationCheckInColor: var(--TurquoiseA400);

  --ClassCapacityColor: var(--Purple500);
  --ClassCancelledColor: var(--Gray300);
}
